import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    // meta:{title:'创业小鸟-让天下没有难创的业'}
  },
  {
    path: '/product/tax',
    name: 'Product',
    meta:{title:''},
    component: () => import('@/views/product'),
  },
  {
    path: '/product',
    name: 'Product',
    redirect:'/product/tax'
  },
  {
    path: '/product/manage',
    name: 'Manage',
    meta:{title:''},
    component: () => import('@/views/product/manage'),
  },
  {
    path: '/product/platform',
    name: 'Platform',
    meta:{title:''},
    component: () => import('@/views/product/platform'),
  },
  {
    path: '/cooperate',
    name: 'Cooperate',
    meta:{title:''},
    component: () => import('@/views/cooperate'),
  },
  {
    path: '/news',
    name: 'News',
    meta:{title:''},
    component: () => import('@/views/news'),
  },
  {
    path: '/news/detail/:id',
    name: 'Newsdetail',
    meta:{title:''},
    component: () => import('@/views/news/detail'),
  },
  {
    path: '/news/video',
    name: 'Video',
    meta:{title:''},
    component: () => import('@/views/news/video'),
  },
  {
    path: '/about',
    name: 'About',
    meta:{title:''},
    component: () => import('@/views/about'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(){
    //x代表横坐标，y代表纵坐标
    return {x:0,y:0};
  }
})

router.beforeEach((to,from,next)=>{
  if (to.meta.title) {
    document.title=to.meta.title
  }
  next()
})

export default router
