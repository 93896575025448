import Vue from 'vue'
import App from './App.vue'
import store from './store'
import request from '@/libs/request.js'
import router from '@/libs/router.js'
import{Button,Form,FormItem,Input,Select,Option,Pagination,Message} from 'element-ui'
import '@/assets/css/index.scss'
import '@/assets/font_icon/iconfont.css'
import 'animate.css'

Vue.prototype.$request=request

import DOMPurify from 'dompurify';

Vue.prototype.$sanitize=function (html) {
   return DOMPurify.sanitize(html);
}

import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
// 使用swiper插件
Vue.use(VueAwesomeSwiper)

import * as util from "./libs/util";
Vue.prototype.$util = util

Vue.prototype.$message = Message;

Vue.use(Button)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Input)
Vue.use(Select)
Vue.use(Option)
Vue.use(Pagination)



Vue.config.productionTip = false


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
