<template lang="">
  <div id="home_page">
    <Top :categoryList="categoryList" :siteList="siteList" />
    <div class="container">
      <!-- 顶部banner -->
      <div v-if="bannerList.length>0" class="top_banner">
        <swiper class="banner_head" :options="bannerSwiper">
          <swiper-slide class="banner_item" v-for="(item,index) in bannerList" :key="index">
            <img :src="item.pic" alt="">
          </swiper-slide>
        </swiper>
        <div class="center_img">
          <img src="@/assets/img/minibanner.png" alt="">
        </div>
        <div class="dot">
          <span v-for="(item,index) in bannerList" :key="index"></span>
        </div>
      </div>
      <!-- 优势 -->
      <div  class="advantage ">
        <div class="advantage_f">
          <h1>
            前端<span>转化</span> 后端<span>赋能</span>
          </h1>
          <div class="advantage_f_item">
            <ul>
              <li>
                <img src="" alt="">
                <span>服务标准化</span>
              </li>
              <li>
                <img src="" alt="">
                <span>客户精细化</span>
              </li>
              <li>
                <img src="" alt="">
                <span>员工效能化</span>
              </li>
              <li>
                <img src="" alt="">
                <span>运营高效化</span>
              </li>
            </ul>
          </div>
          <div class="advantage_video">
            <video controls :src="siteList.company_video"></video>
          </div>
        </div>

        <div ref="animatedElement" class="swiper_content animate__animated" v-if="advantageList.length>0">
          <div class="dot2_content">
            <ul class="dot2">
              <li @click="changeSlide(index)" v-for="(item, index) in advantageList" :key="index"
                :class="{ tab_active: currentIndex === index }">{{ item.title }}</li>
            </ul>
          </div>
          <swiper ref="mySwiper" :options="bannerSwiper2" class="swiper_warp" @slideChangeTransitionEnd="onSlideChange">
            <swiper-slide v-for="(item, index) in advantageList" :key="index" class="swiper_item">
              <div>
                <div class="slide_right">
                  <div v-for="(item2, index2) in item.advantageList2" :key="index2"
                    :class="{'slide_active': item.localIndex === index2}">
                    <span @click="changeTitle(index, index2)"
                      :class="{'slide_right_actived': item.localIndex === index2}">0{{ index2+1 }}</span>
                    <div @click="changeTitle(index, index2)" class="slide_active_right">
                      <p :class="title(index)">{{ item2.c_title }}</p>
                      <!-- <ul class="pro_ul" :style="{ display: item.localIndex === index2 ? 'block' : 'none' }"> -->
                      <!-- <li class="animate__animated animate__fadeIn" v-for="(item3, index3) in item2.content"
                          :key="index3">
                          {{ item3 }}
                        </li> -->
                      <div class="animate__animated animate__fadeIn pro_ul"
                        :style="{ display: item.localIndex === index2 ? 'block' : 'none' }" v-html="item2.c_content">
                      </div>
                      <!-- </ul> -->
                    </div>
                  </div>
                </div>
                <div class="slide_line"></div>
                <img :src="item.pic" alt="">
              </div>
            </swiper-slide>
          </swiper>
        </div>
      </div>
      <!-- 大banner -->
      <div ref="animatedElement2" class="big_banner animate__animated">
        <h1>全流程智能 助力业绩<span>倍速</span>增长</h1>
        <div class="img_content">
          <div class="img_content_r">
            <h1>机智鸟拓客</h1>
            <div class="sec_title">
              线索+商机系统</br>一秒找到海量优质客户
            </div>
            <div class="img_r_item">15万+服务商资源</div>
            <div class="img_r_item">1000+商协会资源</div>
            <button @click=" jumpTax()">了解更多</button>
          </div>
          <!-- <div class="img_content_c">
            <img src="@/assets/img/diannao.png" alt="">
          </div> -->
          <div class="img_content_l">
            <h1>机智鸟ERP</h1>
            <div class="sec_title2">
              一体化作业管理系统</br>让业务全链路加速
            </div>
            <div class="img_l_item">企微一站式协同</div>
            <div class="img_l_item">1000+商协会资源</div>
            <div class="img_l_item">OA管理</div>
            <div class="img_l_item">客资管理</div>
            <div class="img_l_item">智能销售管理</div>
            <div class="img_l_item">服务管理</div>
            <div class="img_l_item">业/财/税一体化管理</div>
            <button @click="jumpManage()">了解更多</button>
          </div>
        </div>
      </div>
      <!-- 咨询框 -->
      <Consult :siteList="siteList" :consultTitle="consultTitle" :smallTitle="smallTitle" />
      <!-- 底部 -->
      <Footer :siteList="siteList" :categoryList="categoryList" />
    </div>
  </div>
</template>
<script>
  import areaData from 'china-area-data';
  import Footer from '@/components/footer';
  import Consult from '@/components/consult';
  import Top from '@/components/top';
  export default {
    components: {
      Footer,
      Consult,
      Top
    },
    props: ['siteList', 'categoryList'],
    data() {
      return {
        bannerList: [],
        provinces: [],
        cities: [],
        directlyAdministeredCities: [],
        advantageList: [],
        consultTitle: '申请全功能体验，为您提供全面的服务',
        smallTitle: '告诉我们您的联系方式，我们的专业顾问将尽快与您取得联系',
        currentIndex: 0,
        imageList: [require("@/assets/img/home_banner01.png"), require("@/assets/img/banner1.png")],
        bannerSwiper: {
          loop: true,
          speed: 2000,
          autoplay: {
            disableOnInteraction: false
          },
          pagination: {
            el: ".dot",
            clickable: true
          },
        },
        bannerSwiper2: {
          // loop: true,
          slidesPerView: 1.65,
          centeredSlides: true,
          spaceBetween: 10,
          speed: 1000,
          on: {
            slideChangeTransitionEnd: function () {
              this.onSlideChange
              this.slides.each((index, slide) => {
                if (index !== this.activeIndex) {
                  slide.classList.add('blur');
                } else {
                  slide.classList.remove('blur');
                }
              });
            }
          }
        }

      }
    },
    created() {
      this.getBanner()
      this.getAdvantage()
    },
    mounted() {
      window.addEventListener('scroll', this.handleScroll);
    },
    beforeDestroy() {
      window.removeEventListener('scroll', this.handleScroll);
    },
    watch: {
      currentIndex(newIndex) {
        this.$refs.mySwiper.swiper.slideTo(newIndex);
      }
    },
    methods: {
      handleScroll() {
    // 定义一个数组来存储需要检查的元素的引用名称
    const elementRefs = ['animatedElement', 'animatedElement2'];
    
    // 遍历每个元素的引用名称
    elementRefs.forEach(refName => {
      const element = this.$refs[refName];
      if (element) {
        const rect = element.getBoundingClientRect();
        const isVisible = rect.top < window.innerHeight && rect.bottom >= 0;
        if (isVisible) {
          // 添加动画类
          element.classList.add('animate__fadeInUp');
          // 移除当前元素的事件监听器
          window.removeEventListener('scroll', () => this.handleScroll(refName));
        }
      }
    });
  },
      changeSlide(index) {
        this.currentIndex = index
        this.advantageList[index].localIndex = 0
        this.$refs.mySwiper.swiper.slideTo(index);
      },
      onSlideChange() {
        this.currentIndex = this.$refs.mySwiper.swiper.activeIndex
      },
      changeTitle(index, index2) {
        this.$set(this.advantageList[index], 'localIndex', index2);
      },
      title(index) {
        return this.currentIndex2 === index ? 'slide_active_right_title' : 'slide_active_right_titles'
      },
      async getAdvantage() {
        let res = await this.$request.post('advantagecategory/getAdv')
        if (res.data.code === 0) {
          this.advantageList = res.data.data
        }
        // 创建一个空对象来存储分组后的数据
        const groupedData = {};

        // 遍历原始数组
        this.advantageList.forEach(item => {
          // 如果分组对象中还没有当前id的键，则创建一个新数组
          if (!groupedData[item.id]) {
            groupedData[item.id] = {
              id: item.id,
              title: item.title,
              pic: item.pic,
              advantageList2: [] // 初始化子内容数组
            };
          }
          // 将当前对象的子内容（c_content, c_title, category_id）添加到对应id的advantageList数组中
          groupedData[item.id].advantageList2.push({
            c_content: item.c_content,
            c_title: item.c_title,
            category_id: item.category_id
          });
        });

        // 将分组后的数据对象转换为数组
        this.advantageList = Object.values(groupedData);
        this.advantageList.forEach(item => {
          this.$set(item, 'localIndex', 0);
        })
      },
      async getBanner() {
        let res = await this.$request.post('banner/getAll')
        if (res.data.code === 0) {
          this.bannerList = res.data.data
        }
      },
      jumpManage() {
        this.$router.push({ path: '/product/manage' })
      },
      jumpTax() {
        this.$router.push({ path: '/product/tax' })
      }
    },
  }
</script>
<style lang="">

</style>