<template lang="">
    <div class="footer">
      <div class="footer_content">
          <div class="footer_top">
                <div class="footer_l">
                     <ul>
                       <li class="footer_l_li">
                         <a href="javascript:void 0;">首页</a>
                         <ul>
                           <li @click="changePath('/')">申请体验</li>
                         </ul>
                       </li>
                       <li class="footer_l_li" v-for="(item,index) in newCategory" :key="index">
                         <a href="javascript:void 0;">{{item.title}}</a>
                         <ul>
                           <li @click="changePath(item.path,item2.sec_path)" v-for="(item2,index2) in item.subItem" :key="index2">{{item2.sec_title}}</li>
                         </ul>
                       </li>
                     </ul>
                </div>
                <div class="footer_r">
                  <div class="img_item">
                    <img :src="siteList.wechat_public" alt="">
                    <span>微信公众号</span>
                  </div>
                  <div class="img_item">
                   <img :src="siteList.wechat_program" alt="">
                   <span>微信小程序</span>
                  </div>
                  <div class="img_item">
                   <img :src="siteList.video_code" alt="">
                   <span>视频号</span>
                  </div>
                  <div class="img_item">
                   <img :src="siteList.dy" alt="">
                   <span>抖音号</span>
                  </div>
                </div>
          </div>
          <div class="footer_center">
              <div class="link">
                 <a href="javascript:void 0;">友情链接: </a>
                 <ul>
                   <li v-for="(item,index) in linkList" :key="index">
                     <a target="_blank" :href="item.link">{{item.title}}</a>
                   </li>
                 </ul>
              </div>
          </div>
          <div class="footer_bottom">
             <span>Copyright{{siteList.copyright}} <a style="text-decoration: none;
              color: #AEAEA7;" href="https://beian.miit.gov.cn/#/Integrated/index">{{siteList.icp_number}}</a>| 营业执照|代理记账许可证</span>
          </div>
      </div>
  </div>
</template>
<script>
export default {
  props:['siteList','categoryList'],
  created() {
    this.getLink()
    this.changeCategory()
  },
  data() {
    return {
      linkList:[],
      newCategory:[]
    }
  },
  methods: {
    async getLink(){
      let res = await this.$request.post('link/getAll')
      if (res.data.code===0) {
        this.linkList=res.data.data
      }
    },
    changePath(path,path2) {
        if (path2) {
          this.$router.push({ path: path2})
        }else{
          let newPath =  this.categoryList.filter(item=>item.path==='/product')[0]
          this.$router.push({ path: path!=='/product'||path!=='/news'?path:newPath.subItem[0].sec_path })
        }
      },
    changeCategory(){
          this.newCategory=JSON.parse(JSON.stringify(this.categoryList));
          
          this.newCategory.forEach(item=>{
            if (item.subItem.length===1&&item.title=='渠道合作'||item.title=='关于我们') {
              if (item.title=='渠道合作') {
                item.subItem.shift()
                item.subItem.push({sec_path:'/cooperate',sec_title:'加入流程'})
              }else{
                item.subItem.shift()
                item.subItem.push({sec_path:'/about',sec_title:'关于机智鸟'})
              }
            }
          })
    }
  },
}
</script>
<style lang="">
  
</style>